import { ReactComponent as BlingIcon } from '../assets/bling/icon-bling.svg';
import { ReactComponent as TinyIcon } from '../assets/tiny/icon-tiny.svg';
import { ReactComponent as EccosysIcon } from '../assets/eccosys/icon-eccosys.svg';
import { ReactComponent as WbuyIcon } from '../assets/wbuy/icon-wbuy.svg';
import { ReactComponent as FrenetIcon } from '../assets/frenet/icon-frenet.svg';
import { ReactComponent as WakeIcon } from '../assets/wake/icon-wake.svg';
import { ReactComponent as ShopifyIcon } from '../assets/shopify/icon-shopify.svg';
import { ReactComponent as TrayIcon } from '../assets/tray/icon-tray.svg';
import { ReactComponent as OmieIcon } from '../assets/omie/icon-omie.svg';
import { ReactComponent as DefaultIcon } from '../assets/default/icon-loggi.svg';
import { ReactComponent as NuvemshopIcon } from '../assets/nuvemshop/icon-nuvemshop.svg';

const iconsList = {
  bling: BlingIcon,
  tiny: TinyIcon,
  eccosys: EccosysIcon,
  wbuy: WbuyIcon,
  frenet: FrenetIcon,
  wake: WakeIcon,
  shopify: ShopifyIcon,
  tray: TrayIcon,
  omie: OmieIcon,
  nuvemshop: NuvemshopIcon,
  default: DefaultIcon
};

export default iconsList;
